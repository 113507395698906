<template>
  <div>
    <h1 class="text-h4">{{ $lang.routes.histories }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="histories"
          :loading="loading"
          @fetchHistories="fetchHistories($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './HistoryDatatable.vue'
import {
  getDeleteHistoriesUsingGET as getHistories
} from '@/utils/api'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      histories: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
  },
  methods: {
    fetchHistories(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.search && options.search.length > 1) {
        obj.resourceName = options.search
      }

      if (options && options.type) {
        obj.resourceType = options.type
      }

      if (options && options.dateFrom) {
        obj.dateFrom = options.dateFrom
      }

      if (options && options.dateTill) {
        obj.dateTill = options.dateTill
      }

      getHistories(obj)
        .then((res) => {
          this.histories = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>
